import Link from "next/link";
import { AnalyticEventNames } from "../utilities/env";
import { analytics } from "../utilities/segment";
import PhoneIcon from "/assets/svgs/phone.svg";

interface Props {
	showIcon?: boolean;
	className?: string;
}

export default function Phone({ showIcon, className }: Props) {
	return (
		<Link
			href="tel:18004817814"
			onClick={(e) =>
				analytics?.track(AnalyticEventNames.phoneNumberLinkClicked, {
					link: e.currentTarget.href,
				})
			}
			className={className}
		>
			{showIcon ? (
				<div className="mx-auto mb-2 w-14 rounded-lg bg-brand-purple-50 p-1 text-brand-purple-500">
					<PhoneIcon />
				</div>
			) : null}
			1-800-481-7814
		</Link>
	);
}
